import React, { memo, useState } from "react";
import { useSelector } from "react-redux";
import { Modal, Button, Typography, Divider } from "@material-ui/core";
import Pages from "../../../../..";
import {
  formatCurrency,
  formatFullName,
  PageLink,
  currencyCodes,
} from "../../../../../../lib";
import { RsvpRegistrationStatuses } from "../../../../RsvpHelpers";
import { AuthSelectors } from "../../../../../../state";
import { FlexBox } from "../../../../../../components";

function EventsStudentsListRow(props) {
  const {
    hasLinkedSubmittedInteraction,
    onRegisterStudent,
    onRemoveStudent,
    showPaidDonated,
    person: {
      allergiesDisplay,
      dietaryNotes,
      cell,
      donationAmount,
      email,
      firstName,
      lastName,
      notes,
      personClassification,
      personClassificationDisplay,
      registrationFeeAmount,
      registrationQuestionResponses,
      statusDisplay,
      studentID,
      tableName,
      sortOrder,
    },
  } = props;

  var [showNotesModal, setShowNotesModal] = useState(false);

  const isShliach = useSelector(AuthSelectors.isLoggedInAsShliach);
  const allowManageStudents = useSelector(AuthSelectors.canManageStudents);

  const hasAllergies =
    allergiesDisplay?.length > 0 &&
    !(allergiesDisplay?.length === 1 && allergiesDisplay[0] === "None");
  const isStudent = personClassification === "Student";
  const cannotRemovePerson = hasLinkedSubmittedInteraction && isStudent;
  const submittedForGrantText = isShliach
    ? "was submitted for a grant"
    : "is locked";

  return (
    <div
      className={`paginated-table-row ${
        showPaidDonated
          ? "rsvp-students-table-row-extended"
          : "rsvp-students-table-row"
      }`}
    >
      <div className="desktop-display-contents">
        <div>
          {!isStudent && (
            <div className="tooltip-container flex">
              <i className="material-icons link-text-secondary accent-text large-text">
                person
              </i>
              <span className="tooltip">{personClassificationDisplay}</span>
            </div>
          )}
        </div>

        <div className="flex flex-align-center">
          {!!studentID && allowManageStudents ? (
            <PageLink
              className="link-text-secondary"
              params={{ studentId: studentID }}
              to={Pages.engagement.studentDetails}
            >
              <div>{formatFullName(firstName, lastName)}</div>
            </PageLink>
          ) : (
            <div>{formatFullName(firstName, lastName)}</div>
          )}

          {(hasAllergies || !!dietaryNotes?.length) && (
            <i className="material-icons ml-8 error-text large-text tooltip-container">
              report
              <span className="tooltip-small-width line-height-double">
                {hasAllergies && `Allergies: ${allergiesDisplay.join(", ")}`}
                {!!dietaryNotes?.length && <div>Info: {dietaryNotes}</div>}
              </span>
            </i>
          )}
        </div>
        <div className="student-table-row-mobile-small-text">{email}</div>
        <div className="student-table-row-mobile-small-text">{cell}</div>
        <div className="flex mobile-mt-8">
          {sortOrder && (
            <p className="desktop-hidden tablet-hidden fw-700 mr-8">Table</p>
          )}
          {tableName || sortOrder}
        </div>
        <div className="student-table-row-mobile-small-text flex">
          {/* TODO: we deprecated the notes field on 2/13/25 (COC-3219) - the client wanted to keep displaying the field for now but we will remove it at a later time*/}
          {(notes ||
            registrationQuestionResponses?.filter((qr) => qr.value).length >
              0) && (
            <React.Fragment>
              <i
                className="material-icons link-text-secondary large-text mobile-hidden"
                onClick={() => setShowNotesModal(!showNotesModal)}
              >
                info_outlined
              </i>
              <p
                className="accent-text tablet-hidden desktop-hidden"
                onClick={() => setShowNotesModal(!showNotesModal)}
              >
                View details
              </p>
            </React.Fragment>
          )}
          {showNotesModal && (
            <Modal
              open={showNotesModal}
              className="modal-container"
              size="medium"
            >
              <div
                className="modal-card card large-modal"
                style={{ padding: 24 }}
              >
                <p className="xl-text fw-900 mb-24">
                  Additional Details - {formatFullName(firstName, lastName)}
                </p>

                {registrationQuestionResponses &&
                  registrationQuestionResponses
                    .filter((qr) => qr.value)
                    .map((qr, i) => (
                      <div key={i}>
                        <FlexBox mb={2}>
                          <Typography variant="subtitle2">Q: </Typography>
                          <Typography variant="body2">
                            {qr.questionText}
                          </Typography>
                        </FlexBox>
                        <FlexBox mb={2}>
                          <Typography variant="subtitle2">A: </Typography>
                          <Typography
                            variant="body2"
                            className="accent-text"
                            style={{ wordBreak: "break-word" }}
                          >
                            {qr.responseType === "YesNoToggle"
                              ? qr.value === "True"
                                ? "Yes"
                                : "No"
                              : qr.value}
                          </Typography>
                        </FlexBox>
                      </div>
                    ))}
                {notes && (
                  <>
                    <div className="fw-700 mt-32 mb-8">Notes </div>
                    <p className="line-height-double pre-wrap accent-text">
                      {notes}
                    </p>
                  </>
                )}
                <Divider style={{ marginLeft: -24, marginRight: -24 }} />
                <div className="text-right">
                  <div className="modal-btns-wrapper mt-8">
                    <Button
                      className="btn-accent"
                      onClick={() => setShowNotesModal(false)}
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </div>
            </Modal>
          )}
        </div>
        <div>
          {statusDisplay === RsvpRegistrationStatuses.Waitlisted ? (
            <p className="link-text" onClick={onRegisterStudent}>
              {statusDisplay}
            </p>
          ) : (
            <p className="student-table-row-mobile-small-text">
              {statusDisplay}
            </p>
          )}
        </div>
        {showPaidDonated && (
          <div className="mobile-hidden">
            {formatCurrency(registrationFeeAmount, currencyCodes.USD)} /{" "}
            {formatCurrency(donationAmount, currencyCodes.USD)}
          </div>
        )}
      </div>
      <div className="tooltip-container">
        <i
          className={`material-icons link-text-secondary accent-text large-text text-right ${
            cannotRemovePerson ? "disabled" : ""
          }`}
          onClick={(e) => {
            e.preventDefault();
            if (!cannotRemovePerson) {
              onRemoveStudent();
            }
          }}
        >
          delete
        </i>
        {cannotRemovePerson && (
          <span className="tooltip-right">
            Students cannot be deleted from this event because this event is
            linked to an interaction that {submittedForGrantText}
          </span>
        )}
      </div>
    </div>
  );
}

export default memo(EventsStudentsListRow);
